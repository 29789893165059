<template>
    <div class="ticket">
        <Steps :steps="processSteps" :active="3" />
        <div v-if="nbrProducts < 1" class="noProducts" >{{$t('errorEmptyTicket')}}</div>
        <div v-else class="ticket-wrapper">
            <h4 class="success-text">{{brandName.toUpperCase()}} {{storeName}}</h4>

            <!-- <span>{{$t('keywordText')}}:</span>
            <h3 class="keyword keyword-ticket">{{ticket.keyword}}</h3> -->

            <div class="qr-code-img-wrapper">
                <img :src="qrCodeImg"/>
            </div>
            <div class="ticket-amount">
                <strong class="used-loyalty" v-if="nbrProducts>0 && ticket.loyaltyToUse">{{formatPrice((ticket.totalAmount - ticket.loyaltyToUse).toFixed(2))}} / {{nbrProducts}} Art. <br> <small>({{$t("loyaltyUsedTicket")}} : {{formatPrice((ticket.loyaltyToUse).toFixed(2))}})</small></strong>
                <strong v-else-if="nbrProducts>0">{{formatPrice(ticket.totalAmount.toFixed(2))}} / {{nbrProducts}} Art.</strong>
                <strong v-else>{{formatPrice(0.00)}} / {{nbrProducts}} Art.</strong>
                <strong v-if="ticket.loyaltyBalance" class="used-loyalty" ><small>({{$t("loyaltyBalanceTicket")}} : {{formatPrice((ticket.loyaltyBalance).toFixed(2))}})</small></strong>

            </div>
            <div class="divider"></div>
            <div class="clock">{{formattedTime}}</div>
            <div class="ticket-products">
                <ProductBloc v-for="cartProduct in sortedCartProducts" :key="cartProduct.id" :product="cartProduct.product" :forcedQty="cartProduct.qty" withBorder hideQty/>
            </div>
        </div>
        <div class="ticket-footer">
            <span :class="{'send-ticket-btn': true, 'disabled': !basketIsValidated}" @click="showSendModal">{{$t("receiveReceipt")}}</span>
            <b-modal centered
                size="sm"
                header-text-variant="light"
                no-close-on-backdrop
                no-close-on-esc
                hide-header
                hide-footer
                style="padding: 0;"
                v-model="showMailModal">

                <div class="food-custom-modal ">
                    <div class="food-custom-modal-header sms-mail-selector-wrapper">
                        <span :class="{'selector': true, 'active': selectedSendMethod == 'mail'}" @click="selectedSendMethod = 'mail'">{{$t("receiptModalMailLabel")}}</span>
                        <span :class="{'selector': true, 'active': selectedSendMethod == 'sms'}" @click="selectedSendMethod = 'sms'">{{$t("receiptModalSMSLabel")}}</span>
                    </div>
                    <template v-if="selectedSendMethod == 'mail'">
                        <span class="food-custom-modal-text">{{$t("receiptModalMailText")}}</span>
                        <input type="email" class="custom-input" v-model="email">
                    </template>
                    <template v-else-if="selectedSendMethod == 'sms'">
                        <span class="food-custom-modal-text">{{$t("receiptModalSMSText")}}</span>
                        <vue-phone-number-input class="my-2" :default-country-code="tel.countryCode || 'FR'" :preferred-countries="['FR', 'BE', 'DE', 'ES', 'PT', 'US', 'GB', 'CH']" v-model="phoneNumber" @update="tel = $event" :no-example="false"/>

                    </template>
                    <div class="delete-modal-actions">
                        <button class="btn nok" @click="showMailModal = false">{{$t("close")}}</button>
                        <button class="btn ok" @click="sendTicket">{{$t("send")}}</button>
                    </div>
                </div>

            </b-modal>
        </div>
    </div>
</template>

<script>
    import {isNotNull, sendMail, sendSMS} from "@/assets/js/Utils"
    import Steps from "@/components/Food/Steps";
    import ProductBloc from "@/components/Food/ProductBloc";
    var qrcodeGenerator = require('qrcode-generator')
    var Utils = require("@/assets/js/Utils");
    var Basket = require("@/assets/js/Food/Basket");
    var Customer = require("@/assets/js/Customer");
    var Stats = require("@/assets/js/Stats");
    var MQTT = require("@/assets/js/Food/MqttService");

    export default {
        name: 'Ticket',
        components: { Steps, ProductBloc },
        props: {
            'paymentSuccess' : String
        },
        data(){
            return{
                customerBasket: null,
                img_url : null,
                ticket : {
                    "id": 0,
                    "totalAmount": 0,
                    "products": [],
                    "lockedProducts" :[]
                },
                qrCodeImg :'',
                fromRoute : null,
                modalShow: false,
                time: "",
                clockParams: {
                    month: "long",
                    weekday: "long",
                    day: "2-digit",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit"
                },
                keyword: sessionStorage.getItem("DKC_food_keyword"),
                processSteps: Utils.getSteps(this.$t),
                storeName: localStorage.getItem("DKC_food_city") || "Seclin",
                brandName: localStorage.getItem("DKC_food_brand") || "Worldline Shop",
                showMailModal: false,
                // TODO RQU : TEMP REMOVE MODAL IDENTIFICATION
                //email: localStorage.getItem("IEC_EMAIL") || "",
                email: "",
                phoneNumber: "",
                selectedSendMethod: 'mail',
                basketIsValidated: false,
                base: localStorage.getItem("DKC_base"),
                tel: JSON.parse(localStorage.getItem("DKC_customerPhoneNumber")) || "",
                page: {
                    titleAtRight: true,
                    title: this.$t("pages.ticket.title"),
                    description: this.$t("pages.ticket.description"),
                    showBasket: false
                }
            }
        },
        beforeRouteEnter (to, from, next) {
            next(vm => {
                vm.fromRoute = from;
        })
        },
        created(){

            if(this.tel != ""){
                this.phoneNumber = this.tel.phoneNumber
            }
            let loadedDemo = sessionStorage.getItem("IEC_loadedDemo")
            if(loadedDemo && loadedDemo == "leader-price"){
                this.brandName = "Leader Price"
            }
            this.$parent.checkDemonstrationLogo();
            let lang = localStorage.getItem("IEC_lang") || this.$route.query.lang || "en"
            this.time = (new Date()).toLocaleString(lang, this.clockParams)
            setInterval(() => {this.time = (new Date()).toLocaleString(lang, this.clockParams)}, 999);
            var config = Utils.getConfig();
            this.img_url = config.url_img;
            var qr = qrcodeGenerator(0, 'L');

            if(isNotNull(this.$route.query.ticketId)){
                var qrData = this.$route.query.ticketId;
                this.$i18n.locale = lang;
                let self = this;
                Basket.getBasketByComplexId(this.$route.query.ticketId, function (ticket) {
                    ticket.basketProducts.forEach( basketProduct => {
                        if(basketProduct.product.ean == "5449000214911"){
                            let nbProductPromos = parseInt(basketProduct.qty / 2)
                            let nbProductNormalPrice = basketProduct.qty - nbProductPromos
                            ticket.totalAmount -= basketProduct.product.price * basketProduct.qty
                            ticket.totalAmount += basketProduct.product.price / 2 * nbProductPromos
                            ticket.totalAmount += basketProduct.product.price * nbProductNormalPrice
                        }
                    })
                    self.ticket = ticket;
                    self.ticket.basketProducts = ticket.basketProducts;
                    qr.addData(qrData);
                    qr.make();
                    self.qrCodeImg = qr.createDataURL(10, 0);
                })
            }else{
                var self = this;
                setInterval(() => {this.time = (new Date()).toLocaleString(lang, this.clockParams)}, 999);
                let customerBasket = this.$parent.basket;
                if(customerBasket.basketProducts.length > 0){
                    Basket.validBasket(function(res) {
                        localStorage.setItem("IEC_basket", JSON.stringify(res));
                        self.$parent.basketChange();
                        /* let mqttMessage = {
                            "encodedTicketId": localStorage.getItem("IEC_encodedTicketId"),
                            "clientFirstname": localStorage.getItem("DKC_firstname"),
                            "clientLastname": localStorage.getItem("DKC_lastname"),
                            "customerComplexId": localStorage.getItem("DKC_complexId"),
                            "lang": self.$i18n.locale
                        }
                        MQTT.publishMessage("PAYMENT_DONE", JSON.stringify(mqttMessage)); */
                        let encodedTicketId = localStorage.getItem("IEC_encodedTicketId");
                        if(isNotNull(encodedTicketId)){
                            qr.addData(encodedTicketId);
                            qr.make();
                            self.qrCodeImg = qr.createDataURL(10, 0);
                        }
                        self.basketIsValidated = true
                    });
                }else{
                    let encodedTicketId = localStorage.getItem("IEC_encodedTicketId");
                    if(isNotNull(encodedTicketId)){
                        qr.addData(encodedTicketId);
                        qr.make();
                        this.qrCodeImg = qr.createDataURL(10, 0);
                    }
                }
                this.ticket = JSON.parse(localStorage.getItem("IEC_ticket"));
                if(this.ticket.loyaltyBalance){
				    localStorage.setItem("IEC_LOYALTY", this.ticket.loyaltyBalance)
                }
                if(!Utils.getLocalSatisfaction("FOOD")){
                    this.modalShow = true;
                }
            }
        },
        methods:{
            handleBack (fallback) {
                if (!this.fromRoute) {
                    this.$router.push(fallback);
                } else {
                    window.location.replace(window.location.origin + this.fromRoute.path);
                }

            },
            formatPrice(price) {
                return Utils.formatPrice(price);
            },
            redirect(){
                window.location.replace(window.location.origin + '/');
            },
            getLang(){
                return this.$root.$i18n.locale;
            },
            showSendModal(){
                if(this.basketIsValidated){
                    this.showMailModal = true;
                }
            },
            sendTicket(){
                let loadedDemo = sessionStorage.getItem("IEC_loadedDemo")
                if(this.basketIsValidated){
                    let encodedTicketId = localStorage.getItem("IEC_encodedTicketId");
                    const ticketUrl = window.location.origin + "/ticket?ticketId=" + encodedTicketId;
                    if(loadedDemo){
                        ticketUrl += `&demoId=${loadedDemo}`
                    }
                    if(this.email != ""){
                        localStorage.setItem("IEC_EMAIL", this.email)
                        sendMail(this.email, ticketUrl, encodedTicketId, this.$i18n.locale);
                    }
                    if(this.tel.isValid){
                        localStorage.setItem("DKC_customerPhoneNumber", JSON.stringify(this.tel))
                        const ticketUrl = "https://awl.li/scan-pay-ticket" + `?` +  "ticketId=" + encodedTicketId
                        if(loadedDemo){
                            ticketUrl += `&demoId=${loadedDemo}`
                        }
                        sendSMS(this.$t("ticketSms") + ticketUrl, this.tel.formattedNumber.replace("+", "00"))
                    }
                }
                this.showMailModal = false
            }
        },
        computed:{
            nbrProducts : function() {
                var count = 0;
                if(this.ticket.basketProducts){
                    this.ticket.basketProducts.forEach(function(cartProduct) {
                        count+=cartProduct.qty;
                    });
                    return count
                }
            },
            sortedCartProducts(){
                return this.ticket.basketProducts.sort((p1, p2) => p2.product.price - p1.product.price)
            },
            formattedTime(){
                let time = this.time.split(" ")
                let result = ""
                return time.map(elmt => {return elmt.charAt(0).toUpperCase() + elmt.slice(1)})
                    .join(" ")
            }
        }
    }
</script>
<style scoped>
    .used-loyalty{
        text-align: end;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
</style>
